import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Siden blev ikke fundet" />
    <div className="flex items-center justify-center lg:max-w-screen-xl mx-auto py-64">
      <div className="text-center px-6">
        <h1 className="text-3xl mb-2">Siden blev ikke fundet</h1>
        <p className="text-gray-800">
          Vi kunne ikke finde, det du søgte. Det kan skyldes, at siden ikke
          findes, at siden er flyttet eller at linket er skrevet forkert.
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
